$('html').mousemove(function (e) {

  var wx = $(window).width();
  var wy = $(window).height();

  var x = e.pageX - this.offsetLeft;
  var y = e.pageY - this.offsetTop;

  var newx = x - wx / 2;
  var newy = y - wy / 2;

  // $('span').text(newx + ", " + newy);

  $('#profile-wrap div').each(function () {
    var speed = $(this).attr('data-speed');
    if ($(this).attr('data-revert')) speed *= -1;
    TweenMax.to($(this), 1, { x: (1 - newx * speed), y: (1 - newy * speed) });

  });

});


AOS.init({
  disable: window.innerWidth < 768,
});

// const links = document.querySelectorAll(".page-header ul a");

// for (const link of links) {
//   link.addEventListener("click", clickHandler);
// }

// function clickHandler(e) {
//   e.preventDefault();
//   const href = this.getAttribute("href");
//   const offsetTop = document.querySelector(href).offsetTop;

//   scroll({
//     top: offsetTop,
//     behavior: "smooth"
//   });
// }

// //Get the button
// let mybutton = document.getElementById("btn-back-to-top");

// // When the user scrolls down 20px from the top of the document, show the button
// window.onscroll = function () {
//   scrollFunction();
// };

// function scrollFunction() {
//   if (
//     document.body.scrollTop > 20 ||
//     document.documentElement.scrollTop > 20
//   ) {
//     mybutton.style.display = "block";
//   } else {
//     mybutton.style.display = "none";
//   }
// }
// // When the user clicks on the button, scroll to the top of the document
// mybutton.addEventListener("click", backToTop);

// function backToTop() {
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
// }